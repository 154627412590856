.projects {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.project {
    display: flex;
    flex-direction: column;
    width: 400px;
    border-radius: 1em;
    transition: transform 0.3s;
}

.project:hover {
    transform: scale(1.05);
}

.projects-list {
    padding: 1em;
    padding-top: 4em;
    width: 100%;
    display: grid;
    justify-items: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 2em;
}

.project-box {
    background-color: #2a2e35;
    padding: 1em;
    display: flex;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.project-image {
    width: inherit;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.project-info {
    display: flex;
    gap: 1em;
    flex-direction: column;
}

.project-tech {
    font-size: 0.85em;
    padding: 0.5em;
    border-radius: 0.75em;
    color: black;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    text-shadow: none;
}

.project-title {
    font-family: 'Cubano';
}

.project-stack {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
}

.project-tech.html {
    background-color: rgb(52, 178, 52);
}

.project-tech.mobilefriendly {
    background-color: rgb(85, 252, 85);
}

.project-tech.css {
    background-color: rgb(79, 79, 251);
    color: white;
}

.project-tech.react {
    background-color: rgb(66, 75, 78);
    color: rgb(19, 222, 212);
}

.project-tech.firebase {
    background-color: orange;
}

.project-tech.nextjs {
    background-color: black;
    color: white;
}

.project-tech.javascript {
    background-color: rgb(236, 249, 90);
    color: black;
}

.project-buttons {
    display: flex;
    gap: 1em;
}

.project-button {
    font-family: 'Cubano';
    border-radius: 0.75em;
    background-color: black;
    padding: 0.5em 1.5em;
    transition: all 0.3s;
}

.project-desc {
    color: lightgrey;
}

.projects-main-title {
    font-family: 'Cubano';
    font-size: 2.75em;
    color: white;
}
.projects-main-info {
    color: rgb(197, 195, 195);
    text-align: center;
}

.project-button:hover {
    cursor: pointer;
    transform: scale(1.2);
}
@media (max-width: 550px) {
    .project {
        width: 300px;
    }
    .project-tech {
        font-size: 0.8em;
    }
}
