.about {
    min-height: 100%;
    color: white;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.tint {
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: multiply;
}
.about-image {
    background: url('../assets/wallpaper2.jpg');
}

.center-align {
    display: grid;
    place-items: center;
    place-content: center;
}

.about-information {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5em;
    width: 100%;
}
.card-information,
.skills-information {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 80vh;
}

.skills-information {
    width: 100vh;
}

.card-title {
    font-family: 'Cubano';
    text-align: center;
    font-size: 2em;
}

.card-desc {
    font-size: 1.25em;
    color: white;
    text-align: justify;
}

.card-hobbies {
    padding-left: 3em;
}

.card-hobby {
    color: white;
}

.highlight {
    color: rgb(236, 249, 90);
}

.strikethrough:hover {
    color: red;
    text-decoration: line-through;
}

.skill-cards {
    display: grid;
    gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
}

.skill-card {
    border: 2px solid rgb(150, 35, 165);
    font-size: 5em;
    padding: 0.5em 0.8em;
    width: 2.5em;
    border-radius: 0.1em;
}

.skills-scroll {
    text-align: center;
    font-weight: bold;
    color: rgb(207, 73, 225);
    cursor: pointer;
    font-family: 'Cubano';
}

.skill-card:hover {
    border: 2px solid rgb(215, 117, 228);
    transform: scale(1.05);
    transition: all 0.25s ease;
}

/* award information */

.award-information {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-desc.awards {
    text-align: center;
}

.small-text {
    font-size: 0.75em;
    text-align: center;
}

.list-divider {
    list-style-type: none;
}
a {
    color: lightblue;
    text-decoration: none;
}

@media (max-width: 420px) {
    .skill-cards {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (max-width: 1024px) {
    .skill-cards {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .skills-information,
    .award-information {
        width: 45vh;
    }

    .card-information {
        width: 45vh;
    }
}

@media (max-width: 550px) {
    .card-information,
    .skills-information,
    .award-information {
        width: 50vh;
    }

    html {
        font-size: 75%;
    }

    .skill-card {
        width: 100%;
    }
    .skill-cards {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}

@media (max-width: 480px) {
    .card-information,
    .skills-information,
    .award-information {
        width: 40vh;
    }

    .card-information {
        width: 80%;
    }
    .skill-cards {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
}
svg {
    height: auto;
    width: auto;
}
