.navbar {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    width: 100%;
    font-weight: bold;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    font-size: 1.2em;
    z-index: 10;
}

.navbar-button,
.hamburger-button {
    display: flex;
    align-items: center;
    gap: 0.25em;
    color: white;
    user-select: none;
    cursor: pointer;
    font-family: 'Cubano';
}

.un {
    padding-bottom: 5px;
    background-image: linear-gradient(rgb(0, 221, 255) 0 0);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 5px;
    background-repeat: no-repeat;
    transition: background-size 0.4s, background-position 0s 0.4s; /*change after the size immediately*/
}

.un:hover {
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 5px;
}

.navbar-links {
    display: flex;
    gap: 2em;
}

.navbar-name {
    font-family: 'Cubano';
    user-select: none;
    padding-left: 1em;
}

.MuiDrawer-paper {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.nav-expand-less {
    display: flex;
    align-self: flex-end;
    padding: 0.5em;
}

.empty-icon {
    width: 40px;
    height: 40px;
}
