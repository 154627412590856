* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Sofia Pro';
    color: cyan;
}

::-webkit-scrollbar {
    display: none;
}

.img-wrapper {
    overflow: hidden;
}

img.hover-zoom {
    transition: all 0.3s ease 0s;
    width: 100%;
}
img.hover-zoom:hover {
    transform: scale(1.25);
}

body {
    background-color: rgba(0, 0, 0, 0.8);
}
